<template>
  <div class="page-container">
    <div class="page-route-select">
      <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
        Home
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <div class="page-route-select-text">
        Clientes
      </div>
    </div>
    <div class="page-title">
      Clientes
    </div>
    <div class="clients-page__buttons-container">
      <Button class="action-buttons" :iconName="'cancel'" title="Limpar Filtros" :text="'Limpar Filtros'" :action="clearFilters" />
      <Button class="action-buttons" :iconName="'person_search'" title="Analítico" :text="'Analítico'" :action="() => { $router.push('/clientes/analitico') }" v-if="can('CLIENT_REGISTRATION_REQUEST', 'READ')"/>
      <Button class="action-buttons" :iconName="'event_note'" title="Solicitações" :text="'Solicitações'" :action="() => { $router.push('/clientes/solicitacoes') }" v-if="can('CLIENT_REGISTRATION_REQUEST', 'READ')" />
      <Button class="action-buttons" :iconName="'add_circle'" title="Adicionar Cliente" :text="'Adicionar Cliente'" :action="() => { open_client_modal() }" />
      <Button class="action-buttons mobile-100-width" :iconName="'person_add'" title="Adicionar Lead" :text="'Adicionar Lead'" :action="() => { open_lead_modal() }" />
    </div>
    <div class="clients-page__actions-container">
      <StandardInput :placeholder="'Digite o cliente'" 
                     :title="'Cliente'" 
                     :action="setNameValue" 
                     :value="name_filter"
                     class="clients-page__actions-inputs mobile-100-width"
                     :type="'text'"/>
      <StandardInput :placeholder="'Selecione o estado'" 
                     :action="setStateValue" 
                     :value="filters.state"
                     :title="'Estado'" 
                     :list="arraySelectState" 
                     class="clients-page__actions-inputs"
                     :type="'select'"/>
      <StandardInputListFilteredAuto class="clients-page__actions-inputs"
                                     title="Cidade" 
                                     :propPlaceholder="selectCity" 
                                     :action="setCityValue"
                                     :value="filters.city"
                                     :list="arraySelectCity"
                                     :actionTwo="() => {}"
                                     :type="'select'"/>
      <StandardInput v-if="isResaleAdmin"
                     :placeholder="'Selecione a revenda'" 
                     :action="setResaleValue" 
                     :value="filters.resale"
                     :title="'Revenda'" 
                     :list="arraySelectResale" 
                     class="clients-page__actions-inputs"
                     :type="'select'"/>
      <StandardInput v-if="!isResaleAdmin"
                     :placeholder="'Selecione a revenda'" 
                     :action="setResaleValue" 
                     :value="filters.resale"
                     :title="'Revenda'" 
                     :list="arraySelectResale" 
                     class="clients-page__actions-inputs"
                     :type="'disabled'"/>
      <StandardInput :placeholder="'Selecione o segmento'" 
                     :action="setSegmentValue" 
                     :value="filters.segment.name"
                     :title="'Segmento'" 
                     :list="arraySelectSegment" 
                     class="clients-page__actions-inputs"
                     :type="'select'"/>
    </div>
    <div class="page-selected-filters-container">
	  <div v-if="name_filter" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ name_filter }}</div>
        <span @click="setNameValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.state.est_nome" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.state.est_nome }}</div>
        <span @click="setStateValue({})" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.city.cid_nome" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.city.cid_nome }}</div>
        <span @click="setCityValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.resale.name" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.resale.name }}</div>
        <span @click="setResaleValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filters.segment.name" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filters.segment.name }}</div>
        <span @click="setSegmentValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
    </div>
    <cc-loader v-show="is_loading" class="default" />
    <no-data-available v-if="total_items == 0 && !is_loading" :text="'Nenhum produto encontrado'" />
    <div v-show="!is_loading && total_items > 0" >
      <div class="page-table-header">
        <div class="page-table-header-text id-width">ID</div>
        <div class="page-table-header-text client-width">Cliente/Nome</div>
        <div class="page-table-header-text cnpj-width">CNPJ/Telefone</div>
        <!-- <div class="page-table-header-text name-width">Nome</div> -->
        <div class="page-table-header-text state-width">Estado/Cidade</div>
        <div class="page-table-header-text segment-width">Segmento</div>
        <div class="page-table-header-text segment-width">Revenda</div>
        <!-- <div class="page-table-header-text phone-width">Telefone</div> -->
        <div class="page-table-header-text actions-width">Ações</div>
      </div>
      <div v-for="(client, idx) in clients"
            :key="'head-office-' + idx">
        <!-- desktop -->
        <div :class="getSpecialBackground(idx)" v-if="client.head_office" class="page-table-row desktop">
          <div style="display: flex; align-items: center;" class="page-table-desc-column id-width">
            <AttendanceStatusCircle style="margin-right: 10px;" v-if="client.attendance && client.attendance.status" :status="client.attendance.status.id" />
            #{{ client.id }}
          </div>
          <div class="page-table-desc-column client-width">
            <div class="page-table-header-mobile">Cliente/Nome</div>
              <div style="display: flex;">
                <div>
                  <span class="material-icons-outlined upload-icon"
                        title="Importar Produtos"
                        v-if="can('CLIENT', 'CREATE')"
                        @click="open_import(client)">
                    file_upload
                  </span>
                  <span @click="setDeleteProducts(client)" v-if="isPerfilId" class="material-icons-outlined delete-products-icon">
                      file_download
                  </span>
                  <router-link :to="{ 
                              name: 'monitors-page', 
                              params: { tab: 'cotacoes' }, 
                              query: { client_id: client.id } 
                            }"
                           :target="'_blank'">
                    <span style="color: lightgreen;" class="material-icons-outlined">storefront</span>
                  </router-link>
                </div>
                <div v-if="client.head_office" style="display: flex; flex-direction: column;margin-left: 10px;">
                  <span class="bold-text-title">{{ client.head_office.social_reazon }}</span>
                  <span class="uppercase-text-title">{{ client.head_office.trading_name }}</span>
                </div>
              </div>
            </div>
          <div class="page-table-desc-column cnpj-width bold-text-title"><div class="page-table-header-mobile">CNPJ/Telefone</div>{{ client.head_office && client.head_office.cnpj || "" | VMask('## ### ###/####-##') }} <br> <cc-whatsapp :phone="client.phone" /> </div>
          <!-- <div class="page-table-desc-column name-width"><div class="page-table-header-mobile">Nome</div>{{ client.head_office.trading_name }}</div> -->
          <div class="page-table-desc-column state-width"><div class="page-table-header-mobile">Estado/Cidade</div><span class="bold-text-title">{{ client.head_office && client.head_office.state && client.head_office.state.name }}</span> <br> {{ client.head_office.city.name || '-' }} </div>
          <div class="page-table-desc-column segment-width"><div class="page-table-header-mobile">Segmento</div>{{ client.sectors && client.sectors.map(s => s.name).filter(s => s).join(', ') }}</div>
          <div class="page-table-desc-column segment-width">
            <div class="page-table-header-mobile">Revenda</div>
            {{ client.resale ? client.resale.name : '--' }}
          </div>
          <!-- <div class="page-table-desc-column phone-width">
            <div class="page-table-header-mobile">Telefone</div>
            <cc-whatsapp :phone="client.phone" />
          </div> -->
          <div class="page-table-desc-column actions-width">
            <div class="page-table-header-mobile">Ações</div>
            <div style="display: flex;">
              <span class="material-icons-outlined groups-icon"
                    title="Gerenciar Usuários"
                    @click="manage_users(client)"
                    v-if="client.head_office.is_head_office && can('CLIENT', 'MANAGE_USERS')">
                groups
              </span>
              <span class="material-icons-outlined store-icon"
                    title="Empresas"
                    @click="open_stores(client)"
                    v-if="client.head_office.is_head_office && can('CLIENT', 'MANAGE_STORES')">
                storefront
              </span>
              <!-- <span class="material-icons-outlined mail-icon"
                    @click="() => open_invitations(client)"
                    title="Convites"
                    v-if="client.head_office.is_head_office && can('CLIENT', 'MANAGE_INVITATIONS')">
                email
              </span> -->
              <span class="material-icons-outlined edit-icon"
                    v-if="can('CLIENT', 'UPDATE')"
                    title="Editar"
                    @click="edit(client)">
                edit
              </span>
              <!-- <span class="material-icons categories-icon"
                    @click="openCategoriesModal(client)"
                    title="Editar Categorias">
                local_offer
              </span> -->
              <!-- <img class="permission-icon"
                    src="../../assets/permission-icon.png"
                    title="Permissões"
                    v-if="can('CLIENT', 'MANAGE_PERMISSIONS')"
                    @click="manage_permissions(client)"> -->
              <span class="material-icons-outlined remove-icon"
                    title="Remover Revenda"
                    v-if="can('CLIENT_REGISTRATION_REQUEST', 'DELETE')"
                    @click="remove(client)">
                delete
              </span>
              <div class="index-more-layer" v-if="client.more" v-on:click="clientMore(client)"></div>
              <div style="position: relative;">
                <span title="Mais opções" class="material-icons-outlined more-icon" v-on:click="clientMore(client)">more_vert</span>
                <div class="index-more-container" v-if="client.more">
                  <p class="index-more-text" @click="() => open_invitations(client)" v-if="client.head_office.is_head_office && can('CLIENT', 'MANAGE_INVITATIONS')">Convites</p>
                  <p class="index-more-text" @click="openCategoriesModal(client)">Editar Categorias</p>
                  <p class="index-more-text" v-if="can('CLIENT', 'MANAGE_PERMISSIONS')" @click="manage_permissions(client)">Permissões</p>
                  <p class="index-more-text" @click="() => {showLogsModal=client; client.more = false}">Logs</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- mobile -->
        <div :class="getSpecialBackground(idx)" class="mobile" > 
          <div class="mobile-table-row">
            <div style="display: flex;">
                <div>
                  <span class="material-icons-outlined upload-icon"
                        title="Importar Produtos"
                        v-if="can('CLIENT', 'CREATE')"
                        @click="open_import(client)">
                    file_upload
                  </span>
                  <span @click="setDeleteProducts(client)" class="material-icons-outlined delete-products-icon">
                    file_download
                  </span>
                </div>
                <div v-if="client.head_office" style="display: flex; flex-direction: column;margin-left: 10px;">
                  <span class="bold-text-title">{{ client.head_office.social_reazon }}</span>
                  <span class="uppercase-text-title">{{ client.head_office.trading_name }}</span>
                </div>
              </div>
              #{{ client.id }}
          </div>
          <div class="mobile-table-row">
            <div style="width: 25%;">
              <div class="page-table-header-mobile">CNPJ</div>
              {{ client.head_office && client.head_office.cnpj || "" | VMask('## ### ###/####-##') }}
            </div>
            <div style="width: 20%;" class="mobile-colum-center">
              <div class="page-table-header-mobile">Telefone</div>
              <cc-whatsapp :phone="client.phone" />
            </div>
            <div style="width: 35%;" class="mobile-colum-end">
              <div class="page-table-header-mobile">Segmento</div>
              {{ client.sectors && client.sectors.map(s => s.name).filter(s => s).join(', ') }}
            </div>
          </div>
          <div class="mobile-table-row">
            <div>
              <div class="page-table-header-mobile">Estado</div>
              {{ client.head_office && client.head_office.state && client.head_office.state.name }}
            </div>
            <div class="mobile-colum-end">
              <div class="page-table-header-mobile">Cidade</div>
              {{ client.head_office && client.head_office.city.name || '-' }}
            </div>
          </div>
          <div class="mobile-table-row">
              <span class="material-icons-outlined groups-icon"
                    title="Gerenciar Usuários"
                    @click="manage_users(client)"
                    v-if="client.head_office && client.head_office.is_head_office && can('CLIENT', 'MANAGE_USERS')">
                groups
              </span>
              <span class="material-icons-outlined store-icon"
                    title="Empresas"
                    @click="open_stores(client)"
                    v-if="client.head_office && client.head_office.is_head_office && can('CLIENT', 'MANAGE_STORES')">
                storefront
              </span>
              <!-- <span class="material-icons-outlined mail-icon"
                    @click="() => open_invitations(client)"
                    title="Convites"
                    v-if="client.head_office.is_head_office && can('CLIENT', 'MANAGE_INVITATIONS')">
                email
              </span> -->
              <span class="material-icons-outlined edit-icon"
                    v-if="can('CLIENT', 'UPDATE')"
                    title="Editar"
                    @click="edit(client)">
                edit
              </span>
              <!-- <span class="material-icons categories-icon"
                    @click="openCategoriesModal(client)"
                    title="Editar Categorias">
                local_offer
              </span> -->
              <!-- <img class="permission-icon"
                    src="../../assets/permission-icon.png"
                    title="Permissões"
                    v-if="can('CLIENT', 'MANAGE_PERMISSIONS')"
                    @click="manage_permissions(client)"> -->
              <span class="material-icons-outlined remove-icon"
                    title="Remover Revenda"
                    v-if="can('CLIENT_REGISTRATION_REQUEST', 'DELETE')"
                    @click="remove(client)">
                delete
              </span>
              <div class="index-more-layer" v-if="client.more" v-on:click="clientMore(client)"></div>
              <div style="position: relative;">
                <span title="Mais opções" class="material-icons-outlined more-icon" v-on:click="clientMore(client)">more_vert</span>
                <div class="index-more-container" v-if="client.more">
                  <p class="index-more-text" @click="() => open_invitations(client)" v-if="client.head_office.is_head_office && can('CLIENT', 'MANAGE_INVITATIONS')">Convites</p>
                  <p class="index-more-text" @click="openCategoriesModal(client)">Editar Categorias</p>
                  <p class="index-more-text" v-if="can('CLIENT', 'MANAGE_PERMISSIONS')" @click="manage_permissions(client)">Permissões</p>
                  <router-link class="index-more-text" :to="{ 
                              name: 'monitors-page', 
                              params: { tab: 'cotacoes' }, 
                              query: { client_id: client.id, name: client.head_office && client.head_office.social_reazon } 
                            }"
                           :target="'_blank'">
                    Cotações
                  </router-link>
                </div>
              </div>
          </div>
        </div>
        <div v-for="(filial, idx) in client.filials" 
              :key="client.id + '-filial-' + filial.id"
              :class="getSpecialBackground(idx)">
          <!-- desktop -->
          <div class="page-table-row desktop">
            <div class="page-table-desc-column id-width">#{{ filial.id }}</div>
            <div style="display: flex;" class="page-table-desc-column client-width">
              <div style="display: flex;">
                <span class="material-icons-outlined upload-icon"
                      title="Importar Produtos"
                      @click="open_import(client)">
                  file_upload
                </span>
                <span class="material-icons-outlined person-icon"
                      :style="{ visibility: 'hidden' }">
                  person
                </span>
              </div>
              <div class="social-trading-wrapper">
                <span class="bold-text-title uppercase-text-title">
                  {{ filial.social_reazon }}
                </span>
                <span class="uppercase-text-title">
                  {{ filial.trading_name }}
                </span>
              </div>
            </div>
            <div class="page-table-desc-column cnpj-width bold-text-title">{{ filial.cnpj || "" | VMask('## ### ###/####-##') }}</div>
            <!-- <div class="page-table-desc-column name-width">{{ filial.trading_name }}</div>  -->
            <div class="page-table-desc-column state-width bold-text-title">{{ filial.state && filial.state.name }}</div>
            <div class="page-table-desc-column segment-width">--</div>
            <div class="page-table-desc-column segment-width">--</div>
            <!-- <div class="page-table-desc-column phone-width"></div> -->
            <div class="page-table-desc-column actions-width"></div>
          </div>
          <!-- mobile -->
          <div class="mobile">
            <div class="mobile-table-row" style="padding: 1vh 0;">
              <div style="width: 40%;">
                <p style="margin: 0;" class="bold-text-title uppercase-text-title">
                  {{ filial.social_reazon }}
                </p>
                <p style="margin: 0;" class="uppercase-text-title">
                  {{ filial.trading_name }}
                </p>
              </div>
                
                <div style="width: 29%;" class="mobile-colum-center">
                  <div class="page-table-header-mobile">CNPJ</div>
                  {{ filial.cnpj || "" | VMask('## ### ###/####-##') }}
                </div>
                <div style="width: 22%;" class="mobile-colum-end">
                  <div class="page-table-header-mobile">Estado</div>
                  {{ filial.state && filial.state.name }}
                </div>
              </div>
          </div>
        </div>

        
      </div>
      <div class="box-footer clearfix not-print">
        <cc-paginacao :key="'pag'"
                      classes="orange"
                      :items_by_page_value="items_by_page_value"
                      :total="total_items"
                      :page="paginaAtual"
                      :page_limit="8"
                      v-on:change="list($event)">
        </cc-paginacao>
      </div>
    </div>
    <deleteProducts v-if="showDeleteProducts" :close="()=>showDeleteProducts=false" :deleteProducts="deleteProducts"/>
    <PermissionsModal v-if="showClientPermissionModal"
                      :closeDialog="closeClientPermissionModal"
                      :owner_name="current_client.name"
                      :id="current_client.id"
                      :type="'CLIENT'" />
    <cc-new-client-modal :id="current_client ? current_client.id : null"
                         :closeDialog="closeEditClientModal"
                         v-if="showEditClientModal" />
    <addLeadModal       :closeDialog="closeLeadModal"
                         v-if="showLeadModal" />
    <cc-invitations-modal v-if="showInvitationsModal"
                          :closeDialog="closeInvitationsModal"
                          :client="current_client"
                          @close="show_invitations=false;current_client=null"
                          @load_sellers="list()"/>
    <user-management-modal :client="current_client"
                           v-if="showUserManagementModal"
                           :closeDialog="closeUserManagementModal" />
    <StoresModal v-if="showEditStoresModal"
                 :client="current_client"
                 :closeDialog="closeEditStoresModal" />
    <cc-import-products-modal v-if="is_import_modal_open"
                              :client="current_client"
                              @close="is_import_modal_open=false;current_client=null" />
    <ManageCategoriesModal v-if="showClientCategoriesModal"
                            :isReadyOnly='!isResaleAdmin'
                            :id="current_client.id"
                           :closeDialog="closeClientCategoriesModal"
                           @close="showClientCategoriesModal = false"/>
    <ClientLogsModal v-if="showLogsModal" :close="()=>showLogsModal=false" :client="showLogsModal"/>
  </div>
</template>

<script>
import Paginacao from "@/components/cliente/base-components/Pagination";
import QuotationService from "@/services/QuotationService";
import ClientService from "@/services/v3/client.service";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import InvitationsModal from "@/modals/client/invitations/invitations.modal";
import NewClientModal from "@/modals/client/new/new-client.modal";
import addLeadModal from "@/modals/client/new/addLeadModal.vue";
import StoresModal from "@/modals/client/stores/management.modal";
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import UserService from "@/services/v1/user.service"
import PermissionsModal from "@/modals/permissions/index.modal"
import UserManagementModal from "@/modals/client/users/management.modal";
import ImportProductsModal from "@/modals/client/products/import-products.modal";
import StorageService from '@/services/TokenService';
import { perm_mixin } from '@/mixins/permission.mixin'
import * as CONSTANTS from '@/constants/constants';
import Button from '@/components/ui/buttons/StandardButton.vue'
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import StandardInputListFilteredAuto from "@/components/ui/inputs/StandardInputListFilteredAuto.vue";
import AttendanceStatusCircle from "@/components/shared/AttendanceStatusCircle";
import ManageCategoriesModal from "@/modals/manage-categories/manage-categories.modal";
import deleteProducts from "./deleteProducts.vue";
import ClientLogsModal from "../../modals/client/ClientLogsModal.vue";

export default {
    mixins: [ loaderMixin, perm_mixin ],
    components: {
        ccPaginacao: Paginacao,
        StoresModal,
        PermissionsModal,
        'user-management-modal': UserManagementModal,
        'cc-invitations-modal' : InvitationsModal,
        'cc-new-client-modal' : NewClientModal,
        'cc-import-products-modal' : ImportProductsModal,
        ManageCategoriesModal,
        Button,
        StandardInput,
        StandardInputListFilteredAuto,
        AttendanceStatusCircle,
        addLeadModal,
        deleteProducts,
        ClientLogsModal
    },
    data(){
        return{
          showClientCategoriesModal: false,
          isResaleAdmin: undefined,
          showInvitationsModal: false,
          showUserManagementModal: false,
          showEditStoresModal: false,
          showEditClientModal: false,
          showLogsModal: false,
          showClientPermissionModal: false,
          isPerfilId: localStorage.getItem('perfilId') == '1',
          showLeadModal: false,
          arraySelectState: [],
          arraySelectResale: [],
          arraySelectCity: [],
          arraySelectSegment: [],
          showDeleteProducts: false,
          image:"/images/search.png",
          is_loading: false,
          paginaAtual: 1,
          total_items: 1,
          name_filter: "",
          states: [],
          cities: [],
          resales:[],
          filters: {
            city: {},
            state: {},
            resale: {},
            segment: {
              id: undefined,
              name: undefined,
            },
          },
            show_invitations: false,
            current_client: {},
            is_client_modal_open: false,
            is_stores_modal_open: false,
            is_client_permissions_modal_open: false,
            is_user_management_modal_open: false,
            is_import_modal_open: false,
            items_by_page_value: 50,
            service: new QuotationService(),
            svc: new ClientService(),
            user_svc: new UserService(),
            storage_svc: new StorageService(),
            clients: [],
            statuses: [ { key: 'Todos', value: null }, { key: 'Inativos', value: 0 }, { key: 'Ativos', value: 1 } ],
            manage_groups: false,
            revenda_id: null,
            breadcrumbs: [
                {
                    name: "Meus Vendedores",
                    route: 'sellers'
                }
            ],
            selectCity: 'Selecione a cidade',
        }
    },
    methods: {
        clientMore(client) {
          client.more = !client.more
          this.$forceUpdate()
        },
        setDeleteProducts (client) {
          this.showDeleteProducts = client.id
        },
        deleteProducts () {
          this.is_loading = true
          this.svc.deleteProducts(this.showDeleteProducts).then(() => {
            this.showDeleteProducts = false
            this.$swal.fire(
                "Realizado!",
                "Produtos excluidos com sucesso.",
                "success"
            );
            this.is_loading = false
          }).catch(error => {
            this.showDeleteProducts = false
            if (error.response.data.message == "products client not found") this.$swal.fire(
              "Erro!",
              "Não haviam produtos para serem excluidos",
              "error"
            );
            this.is_loading = false
          })
        },
        openCategoriesModal(client) {
          this.showClientCategoriesModal = true;
          this.current_client = client
        },
        closeClientCategoriesModal: function () {
            this.showClientCategoriesModal = false;
        },
        getClientsSegmentList() {
          const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
           }
          axios.get(`${CONSTANTS.API_V3_URL}/admin/clients/ramo_clientes`, config)
          .then(data => {
            data.data.forEach(element => {
              this.arraySelectSegment.push({
                text: element.ramo_descricao,
                value: element
              })
            })
          }).catch(error => {
            console.log(error)
          })
        },
        setCityValue(value) {
          this.filters.city = value
          setTimeout(() => {
            this.list(this.page)
          }, 1500)
        },
        getResaleId() {
          this.revenda_id = this.storage_svc.get("revenda");
          if (this.revenda_id < 2) {
            this.isResaleAdmin = true
          } else {
            this.isResaleAdmin = false
            this.filters.resale = {id: this.revenda_id}
          }
        },
        setSegmentValue(value) {
          this.filters.segment.id = value.ramo_id;
          this.filters.segment.name = value.ramo_descricao;
          setTimeout(() => {
            this.list()
          }, 400);
        },
        setNameValue(value){
		  this.name_filter = value
          setTimeout(() => {
            this.list()
          }, 400);
		},
        setStateValue(value){
		    this.filters.state = value
          setTimeout(() => {
            this.list()
          }, 400);
		},
        setResaleValue(value){
		  this.filters.resale = value
          setTimeout(() => {
            this.list()
          }, 400);
		},
        closeInvitationsModal: function() {
          this.showInvitationsModal = false;
        },
        closeUserManagementModal: function() {
          this.showUserManagementModal = false;
        },
        closeEditStoresModal: function() {
          this.showEditStoresModal = false;
        },
        closeEditClientModal: function() {
          this.showEditClientModal = false;
        },
        closeLeadModal: function() {
          this.showLeadModal = false;
        },
        closeClientPermissionModal: function() {
          this.showClientPermissionModal = false;
        },
        clearFilters() {
          this.name_filter = ""
          if (this.isResaleAdmin) this.filters.resale = ''
          this.filters.state = ''
          this.filters.city = ''
          this.filters.segment = ''
          setTimeout(() => {
            this.list()
          }, 400);
        },
        getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        remove(client) {
            this.confirm_action({
                message: `Confirma remoção do cliente ${client.name}?`,
                subtitle: `O acesso do cliente e seus usuarios serão cancelados`,
                callback: () => {
                    this.svc.remove(client.id).then(this.list)
                }
            })
        },
        manage_permissions(client) {
            this.showClientPermissionModal = true
            this.current_client = client
        },
        manage_users(client) {
            this.current_client = client
            this.showUserManagementModal = true;
        },
        edit(client) {
            this.current_client = client
            this.showEditClientModal = true
        },
        open_client_modal() {
            this.current_client = null
            this.showEditClientModal = true
        },
        open_lead_modal() {
            this.showLeadModal = true
        },
        open_invitations(client) {
            this.current_client = client
            this.show_invitations = true
            this.showInvitationsModal = true
        },
        open_stores(client) {
            this.current_client = client
            this.showEditStoresModal = true
        },
        open_import(client) {
            this.current_client = client
            this.is_import_modal_open = true
        },
        prefix_cnpj(cnpj) {
            let result = `${cnpj}`
            while(result.length < 14) {
                result = "0" + result
            }
            return result
        },
        load_region_data() {
		    	return this.user_svc.region_data().then(response => response.data).then(data => {
				    this.states = data.estados
            this.cities = data.cidades
            data.estados.forEach(element => {
              this.arraySelectState.push({
                text: element.est_nome,
                value: element
              })
            })
            data.cidades.forEach(element => {
              this.arraySelectCity.push({
                text: element.cid_nome,
                value: element
              })
            })
			})
		},
        list(new_page = this.paginaAtual) {
            this.paginaAtual = new_page;
            this.is_loading = true;
            this.svc.list({
                page: new_page,
                name: this.name_filter,
                state: this.filters.state?.est_sigla,
                city: this.filters.city?.cid_id,
                ramo: this.filters.segment?.id,
                resale: this.filters.resale?.id,
                sort: 'l.loj_razao'
            }).then(response => response.data).then(data => {
                this.clients = data.data;
                this.total_items = data.meta.total;
                this.is_loading = false;
            })
            .catch(error => {
                this.is_loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        load_resales() {
            return this.svc.load_resales().then(response => response.data).then(data => {
                this.resales = data.data
                data.data.forEach(element => {
                  this.arraySelectResale.push({
                    text: element.name,
                    value: element
                  })
                })
            })
		},
    },
    computed: {
		available_cities : function() {
			return this.filters.state
                ? this.cities.filter(c => c.est_id == this.filters.state.est_id)
                : []
		}
	},
    async created() {
      this.getResaleId()
      this.getClientsSegmentList()

      this.load_region_data()
      this.list();
      this.load_resales();
      this.$store.dispatch('set_breadcrumbs', this.breadcrumbs); 
    }
}
</script>

<style lang="scss" scoped>
    @import '@/sass/commons/_tables';

  

    .seller-icon {
        color: $blue1;
        padding: 0 0.6rem;
        font-size: 1rem;
    }

    .filter-controls {
        button {
            margin-right: 10px;
        }
    }

    .fa-upload{
        cursor: pointer
    }

    .rate {
        background-color: white;
        padding: 0.5rem 0.5rem;
        border-radius: 5px;
        color: black;
        cursor: pointer;
        i {
            font-size: 1.3rem;
            color: $yellow1;
        }
        &:hover {
            color: white;
            background-color: $blue1;
            i {
                color: white;
            }
        }
    }

    .actions {
        justify-content: center;
        white-space: nowrap;
        i {
            font-size: 1.1rem;
            cursor: pointer;
            &.fa-trash {
                color: red;
            }
            &.fa-id-card {
                color: lightseagreen !important;
            }
            &.fa-store {
                font-size: 1rem;
            }
        }
    }

.page-container {
  font-size: 1vw;
  margin-right: 4em;
}
.page-route-select {
  display: flex;
  align-items: center;
}
.page-route-select-text {
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon {
  color: var(--primary-color);
  margin-right: 20px;
}
.page-title {
  font-weight: 600;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 45px;
}
.more-icon{
  cursor: pointer;
}
.index-more-container{
  position: absolute;
  right: 0;
  background-color: white;
  border-radius: 10px;
  z-index: 3;
  padding: 1vh 0;
  outline: 1px solid lightsalmon;
}
.index-more-layer{
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0%;
  width: 100%;
  height: 125vh;
}
.index-more-text{
  min-width: 11.5vw;
  cursor: pointer;
  text-align: right;
  margin: 0;
  padding: 0.5vh 0;
  padding-right: 0.5vw;
  font-size: 1.1em;
  color: gray;
  display: block;
}
.index-more-text:hover{
  background-color: lightsalmon;
  color: white;
}
.clients-page__buttons-container {
  display: flex;
  flex-direction: row-reverse;
  margin: 2em 0em;
}
.action-buttons {
  margin-left: 20px;
}
.clients-page__actions-container {
  display: flex;
  justify-content: space-between;
  margin: 3em 0em;
}
.clients-page__actions-wrapper {}
.clients-page__input-title-wrapper {
  padding-bottom: 5px;
}
.clients-page__actions-inputs{
  width: 18%;
}
.clients-page__input-title {
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
  font-size: 1.6em;
}
.select-input-width {
  width: 30vw !important;
  margin-right: 10px;
}
.clients-page__product-input-container {
  display: flex;
  align-items: center;
}
.clients-page__product-input {
  border: 1px solid #c2c2c3;
  font-size: 17px;
  padding: 11px;
  border-radius: 5px;
  width: 30vw;
}
.clients-page__product-input:focus {
  border: 1px solid #c2c2c3 !important;
}
.search-icon {
  position: relative;
  left: -35px;
  color:  #606060;
}
.page-table-content {
  min-width: 1300px;
}
.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
}
.page-table-header-mobile{
    display: none;
  }
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600; 
  font-size: 1.19em;
  color: #505050;
}
.page-table-row {
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 1.0em;
  color: #605F5F;
}
.page-table-desc-column {
  padding: 0.5em 0.7em;
  font-size: 1.1em;
}
.page-table-line-special {  
  background: #F7F7F7;
}
.id-width {
  width: 6%;
}
.client-width {
  flex: 1;
}
.cnpj-width {
  width: 12.5%;
}
.name-width {
  width: 12.5%;
}
.state-width {
  width: 10.5%;
}
.segment-width {
  width: 8%;
}
.phone-width {
  width: 8%;
}
.actions-width {
  width: 17%;
  justify-content: center;
  display: flex;
}
.upload-icon {
  position: relative;
  font-size: 1.5em;
  cursor: pointer;
  color: #606060;
}
.person-icon {
  position: relative;
  font-size: 1.5em;
  color: #0677C9;
}
.delete-products-icon{
  font-size: 1.5em;
  color: lightcoral;
  cursor: pointer;
}
.groups-icon {
  color: #46AD5D;
  cursor: pointer;
  margin-right: 8px;
  font-size: 1.5em;
}
.store-icon {
  color: #0677C9;
  cursor: pointer;
  margin-right: 8px;
  font-size: 1.5em;
}
.mail-icon {
  color: var(--primary-color);
  cursor: pointer;
  margin-right: 8px;
  font-size: 1.5em;
}
.categories-icon {
  color: var(--primary-color);
  cursor: pointer;
  margin-right: 8px;
  font-size: 1.5em;
}
.edit-icon {
  color: #606060;
  cursor: pointer;
  margin-right: 8px;
  font-size: 1.5em;
}
.permission-icon {
  cursor: pointer;
  margin-right: 8px;
  padding-bottom: 14px;
  font-size: 1.5em;
}
.remove-icon {
  color: #CB4243;
  cursor: pointer;
  margin-right: 8px;
  font-size: 1.5em;
}
.page-selected-filters-container {
  display: flex;
  margin-bottom: 2em;
}
.page-selected-filters {
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.page-selected-filters-text {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-right: 5px;
}
.page-selected-filters-icon {
  color: var(--primary-color);
  cursor: pointer;
}
.bold-text-title {
  font-weight: bold;
  font-size: 1.2em;
}
.uppercase-text-title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.1em;
}
.social-trading-wrapper {
  display: flex; 
  flex-direction: column; 
  margin-left: 10px;
}
.mobile{display: none;}

@media only screen and (max-width: 850px) {
  .mobile{display: block;}
  .actions-width {    width: 13%;  }
  .state-width {    width: 10%;  }
  .page-container {    font-size: 2.5vw;  }
  .page-table-header{   display: none;  }
  .page-table-row {
    flex-direction: column;
  }
  .page-table-header-mobile{
    font-weight: 600;
    font-size: 1.1em;
    color: #505050;
    display: flex;
  }
  .page-table-desc-column {
    display: flex;
    justify-content: space-between;
    text-align: end;
  }
  .id-width {
    width: 100%;
  }
  .client-width {
    flex: unset;
    width: 100%;
  }
  .cnpj-width {
    width: 100%;
  }
  .name-width {
    width: 100%;
  }
  .state-width {
    width: 100%;
  }
  .segment-width {
    width: 100%;
  }
  .phone-width {
    width: 100%;
  }
  .actions-width {
    width: 100%;
  }
  .clients-page__buttons-container{
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 2vh 0 0 0;
  }
  .action-buttons{
    width: 45%;
    margin-left: 0;
    margin-bottom: 1vh;
  }
  
  
  .clients-page__actions-container{    
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
  }
  .clients-page__actions-inputs{    width: 45%;  }
  .mobile-100-width{
    width: 100%;
  }
  .mobile-table-row{
    display: flex;
    justify-content: space-between;
    color: #505050;
  }
  .index-more-text{
    min-width: 30vw;
    text-align: center;
  }
  .mobile-colum-end{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .mobile-colum-center{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .desktop{display: none;}
}
@media only screen and (max-width: 550px){
  .page-container {    font-size: 3vw; margin-right: 3vw;  }
}
@media only screen and (max-width: 550px){
  .page-container {    font-size: 3.5vw; }
}
</style>
