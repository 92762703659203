<template>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="new-product__header-container">
                <span class="new-product__header-title">
                    Logs - {{ client.head_office.social_reazon }}
                </span>
                <span @click="close()" class="material-icons closebtn-icon">
                    cancel
                </span>
            </div>
            <div class="log-modal-body">
                <StandardInput :title="'Tipo'" style="margin-top: 3vh; width: 20%;"
                               :action="v=>selectedType=v"
                               :type="'select'"
                               :list="options"/>
                <table style="margin-top: 3vh;">
                    <tr class="log-table-header">
                        <th style="padding-left: 1vw;" class="base-width">Id</th>
                        <th class="date-width">Data</th>
                        <th class="base-width">Tipo</th>
                        <th class="base-width">Modelo</th>
                        <th class="date-width">Usuário</th>
                        <th class="log-width">Log</th>
                    </tr>
                    <tr class="log-table-row" v-for="(log, idx) in logs" :key="log.id"
                        :class="{ 'page-table-line-special': idx % 2 != 0 }">
                        <td>{{ log.id }}</td>
                        <td>{{ log.created_at }}</td>
                        <td :style="getActionColor(log)">{{ log.action }}</td>
                        <td>{{ log.model.split('\\').slice(-1)[0] }}</td>
                        <td>{{ log.usuario.usu_nome }}</td>
                        <td v-if="log.action == 'update'">
                            <p v-for="line in getDetailUpdate(log.details)">
                                {{ line.field }}: <b style="color: lightcoral;">{{ line.old }}</b> => <b style="color: lightgreen;">{{line.changed}}</b>
                            </p>
                        </td>
                        <td v-if="log.action == 'create'">
                            <p v-for="line in getDetailCreate(log.details)">
                                {{ line[0] }}: <b style="color: lightgreen;">{{line[1]}}</b>
                            </p>
                        </td>
                    </tr>
                </table>
                <cc-pagination classes="orange" style="margin-top: 2vh;" :items_by_page_value="20" :page_limit="9"
                    :total="total_items" :page="1" v-on:change="updatePage($event)"></cc-pagination>
            </div>
        </div>
        <cc-loader-full v-if="isLoading"/>
    </div>
</template>

<script>
import DashboardService from '@/services/v1/user.service';
import Paginacao from "@/components/cliente/base-components/Pagination";
import StandardInput from '@/components/ui/inputs/StandardInput.vue'

export default {
    name: 'ClientLogsModal',
    props: {
        close: {
            type: Function
        },
        client: Object
    },
    components: {
        ccPagination: Paginacao,
        StandardInput
    },
    data() {
        return {
            logs: [],
            svc: new DashboardService,
            tab: 'cli',
            total_items: 0,
            clientsList: [],
            clientName: '',
            isLoading: true,
            options: [],
            selectedType: undefined
        }
    },
    watch:{
        selectedType(){
            this.updatePage(1)
        }
    },
    methods: {
        isActiveContainer(tab) {
            if (this.isActiveTab(tab)) return 'page-section-select-size-active'
        },
        isActiveIcon(tab) {
            if (this.isActiveTab(tab)) return 'active-icon'
        },
        isActiveTab(tab) {
            if (tab == this.tab) return true
            return false
        },
        getActionColor(log){
            const color = log.action == 'update' ? 'lightblue':'lightgreen'
            return 'color: ' + color
        },
        getDetailUpdate(value){
            const resp = []
            const updates = value.split('\n')
            updates.forEach(element => {
                const field = element.split(' - ')[0].replace('Field: ','')
                const old = element.split('Old Value: ')[1].split(',')[0]
                const changed = element.split('New Value: ')[1]
                resp.push({field, old, changed})
            });
            return resp
        },
        getDetailCreate(value){
            return Object.entries(JSON.parse(value))
        },
        updatePage(page) {
            this.isLoading = true
            const params = { page, cli_id: this.client.id, model: this.selectedType }
            this.svc.getLog(params).then(resp => {
                this.logs = resp.data.data
                this.total_items = resp.data.total
                this.isLoading = false
            })
        },
        setCompany(value) {
            this.clientId = value.id
            this.updatePage(1)
        },
        changeSelect(value) {
            if (!value) return
            this.clientName = value
            if (!this.loading) {
                this.loading = true
                setTimeout(() => {
                    this.requestClients()
                }, 1500)
            }
        },
        requestClients() {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                params: {
                    name: this.clientName,
                },
            };
            axios
                .get(`${CONSTANTS.API_V3_URL}/admin/clients?page=1&sort=l.loj_razao`, config)
                .then(data => {
                    this.loading = false
                    this.clientsList = []
                    data.data.data.forEach(element => {
                        this.clientsList.push({
                            text: element.name,
                            value: element
                        });
                    });
                    this.$forceUpdate()
                });
        },
    },
    mounted() {
        const params = { page: 1, cli_id: this.client.id }
        this.svc.getLog(params).then(resp => {
            this.logs = resp.data.data
            this.total_items = resp.data.total
            this.isLoading = false
        })
        this.svc.getLogOptions().then(resp=>{
            this.options = resp.data.map(v =>{
                return {text: v.model.split('\\').slice(-1)[0], value: v.model.split('\\').slice(-1)[0]}
            })
        })
    },
}
</script>

<style lang="scss" scoped>
.modal-mask {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
}

.modal-content-container {
    background-color: white;
    width: 95vw;
    border-radius: 12px;
}

.new-product__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--primary-color);
}

.new-product__header-title {
    color: #ffffff;
    font-weight: 500;
    font-size: 32px;
}

.closebtn-icon {
    color: #ffffff;
    font-size: 30px;
    cursor: pointer;
}

.log-table-header {
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 400;
    color: #605F5F;
}

.log-table-row {
    font-weight: 300;
    font-size: 1.0em;
    color: #605F5F;
}

.page-table-line-special {
    background: #F7F7F7;
}

.base-width {
    width: 11%;
}
.date-width{
    width: 17%;
}
.log-width{
    flex: 1;
}
.log-modal-body{
    padding: 0 5%;
    height: 100vh;
    overflow: auto;
    padding-bottom: 5vh;
}
p{
    margin: 0;
}
</style>